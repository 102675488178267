@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400&display=swap');


* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  
}

body {
  font-family: 'Poppins', sans-serif;
}

.randBox {
  font-size: 100.0%;
  border: 1px solid black;
  max-width: 1500px;
  margin-top: 30px;
  margin-bottom: 30px;
  margin-right: auto;
  margin-left: auto;

  min-height: 100px;
  overflow: auto;

  padding: 30px;
  
  border: 1px solid #888;
  border-radius: 5px;
}

.header {
  margin: 35px auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.btn2 {
  display: inline-block;
  background: #888;
  color: #fff;
  border: none;
  padding: 10px 20px;
  margin: 5px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 15px;
  font-family: inherit;
}

.btn:active {
  transform: scale(0.98);
}

.btn-block {
  display: block;
  width: 100%;
}

.task {
  margin: 5px;
  padding: 10px 20px;
}

.task.reminder {
  border-left: 5px solid green;
}

.task h1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.task h3 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.add-form {
  margin-bottom: 40px;
}

.form-control {
  margin: 20px 0;
}

.form-control label {
  display: block;
}

.form-control input {
  width: 100%;
  height: 40px;
  margin: 5px;
  padding: 3px 7px;
  font-size: 17px;
}

.form-control-check {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.form-control-check label {
  flex: 1;
}

.form-control-check input {
  flex: 2;
  height: 20px;
}

footer {
  margin-top: 30px;
  text-align: center;
}

.navbar {
  border-bottom: 1px solid #888;
}

/* Slider von ImageViewer */
.d-block {
  width: 100vw !important;
  height: 70vh !important; 
  background-size: cover !important;
  background-position: center;
}

/* Kontakt */
.about-image{
  width: 320px;
  height: 320px;
  border-radius: 50%;
  background-size: cover !important;
  background-position: center;
}

/* icon in Navbar */
.icon{
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-size: cover !important;
  background-position: center;
}

/* News */
.news-image{
  width: 320px;
  height: 320px;
  background-size: cover !important;
  background-position: center;
  padding: 30px;
  margin: 30px auto;
}

.icon-link:active svg,
.icon-link:hover svg {
  color: #888 !important;
}

.list-group-flush{
  margin: 0x !important;
  padding: 0px 0px !important;
}